// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Fonts/boldfreesans.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Fonts/freesans.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n\n\n\nbody {\n    text-align: center;\n     /* <------------ */\n   \n\n}\n\n.maindiv {\n    width: 954px;\n    margin: 0px auto;\n    min-height: 100vh;\n\n \n    \n\n}\n@font-face {\n    font-family: myFirstFont1;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  }\n\n@font-face {\n    font-family: myFirstFont;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  }\n\nh1{\n\n\ncolor: #fff;\nfont-family: myFirstFont1;\nmargin-top: 100px;\n\n\n\n}\n\n.adtitles{\n\nmargin-top: 60px;\n\n    \n}\n\nh3{\n\n    color: #fff;\nfont-family: myFirstFont;\n\n\n}", "",{"version":3,"sources":["webpack://./src/assets/landingPage.css"],"names":[],"mappings":";;;;AAIA;IACI,kBAAkB;KACjB,kBAAkB;;;AAGvB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;;;;;AAKrB;AACA;IACI,yBAAyB;IACzB,4CAAkC;EACpC;;AAEF;IACI,wBAAwB;IACxB,4CAA8B;EAChC;;AAEF;;;AAGA,WAAW;AACX,yBAAyB;AACzB,iBAAiB;;;;AAIjB;;AAEA;;AAEA,gBAAgB;;;AAGhB;;AAEA;;IAEI,WAAW;AACf,wBAAwB;;;AAGxB","sourcesContent":["\n\n\n\nbody {\n    text-align: center;\n     /* <------------ */\n   \n\n}\n\n.maindiv {\n    width: 954px;\n    margin: 0px auto;\n    min-height: 100vh;\n\n \n    \n\n}\n@font-face {\n    font-family: myFirstFont1;\n    src: url(./Fonts/boldfreesans.ttf);\n  }\n\n@font-face {\n    font-family: myFirstFont;\n    src: url(./Fonts/freesans.ttf);\n  }\n\nh1{\n\n\ncolor: #fff;\nfont-family: myFirstFont1;\nmargin-top: 100px;\n\n\n\n}\n\n.adtitles{\n\nmargin-top: 60px;\n\n    \n}\n\nh3{\n\n    color: #fff;\nfont-family: myFirstFont;\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
