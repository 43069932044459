// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footerStyle_footerDiv__Z1rsf{\n\ndisplay: flex;\njustify-content: space-around;\nalign-items: center;\n\n\n\n}", "",{"version":3,"sources":["webpack://./src/assets/footerStyle.module.css"],"names":[],"mappings":"AAAA;;AAEA,aAAa;AACb,6BAA6B;AAC7B,mBAAmB;;;;AAInB","sourcesContent":[".footerDiv{\n\ndisplay: flex;\njustify-content: space-around;\nalign-items: center;\n\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerDiv": "footerStyle_footerDiv__Z1rsf"
};
export default ___CSS_LOADER_EXPORT___;
